// Import necessary modules from Three.js
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { MeshTransmissionMaterial } from './MeshTransmissionMaterial.js';

var cylinderMaterial;
// Function to create the ring with text
function createRing({ text, radius, height, segments }) {
    // Create a group to hold the cylinder and text
    const group = new THREE.Group();

    // Create the cylinder geometry and material
    const cylinderGeometry = new THREE.CylinderGeometry(radius, radius, height, segments);
    cylinderMaterial = new MeshTransmissionMaterial({
        transmission: 0,
        backside: true,
        thickness: 1,
        backsideThickness: 1.0,
        resolution: 512,
        backsideResolution: 512,
        background: new THREE.Color(0x000000),
    });

    // Create the cylinder mesh and add it to the group
    const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
    group.add(cylinder);

    // Calculate positions for text
    const textPositions = [];
    const angleStep = (2 * Math.PI) / text.length;
    for (let i = 0; i < text.length; i++) {
      const angle = i * angleStep;
      const x = radius * Math.cos(angle);
      const z = radius * Math.sin(angle);
      textPositions.push({ x, z });
    }

    // Create a font loader to load the font
    const loader = new FontLoader();
    loader.load('/fonts/helvetiker_regular.typeface.json', function (font) {
      text.split('').forEach((char, index) => {
        const textGeometry = new TextGeometry(char, {
          font: font,
          size: 0.3,
          height: 0.1,
        });

        const textMaterial = new THREE.MeshNormalMaterial({});

        const textMesh = new THREE.Mesh(textGeometry, textMaterial);
        textMesh.position.set(textPositions[index].x, 0, textPositions[index].z);
        textMesh.rotation.y = -angleStep * index + Math.PI / 2;
        group.add(textMesh);
      });

      console.log("Text added to the scene");
    });

    console.log("Ring created");
    return group;
}

// Function to animate the group
function animate(group) {
    group.rotation.y += 0.01;
    group.rotation.x += 0.01;
    group.rotation.z += 0.01;
    requestAnimationFrame(() => animate(group));
}

// Main function to initialize the scene, camera, renderer, and add the ring
function main() {
    const canvas = document.querySelector('.about');
    if (!canvas) {
        console.error('Canvas element with class "what" not found');
        return;
    }

    // Create the scene
    const scene = new THREE.Scene();
    scene.background = null;
    // scene.background = new THREE.Color(0x000000);  // Set a black background
    // scene.background = new THREE.Color(0xffffff);  // Set a white background

    // Create the camera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;
    camera.position.y = -1;

    // Create the renderer and attach it to the canvas
    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true  });
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Create the ring and add it to the scene
    const ring = createRing({
        // text: "X X X X X X X X X X X X X X X X X X X X X X X X X X X X ",
        text: "EVITAERC               NGISED               EDOC               WEB               ",
        radius: 2,
        height: 1,
        segments: 32,
    });
    scene.add(ring);

    // Animate the ring
    animate(ring);

    // Render the scene
    function render() {
        requestAnimationFrame(render);
        cylinderMaterial.render(renderer, scene, camera);
        renderer.render(scene, camera);
    }
    render();
}

// Run the main function
main();
