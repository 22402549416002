import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';

const canvas = document.querySelector('canvas.webgl');
const renderer = new THREE.WebGLRenderer({ antialias: true, canvas });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);

const scene = new THREE.Scene();

const fov = 75;
const aspect = window.innerWidth / window.innerHeight;
const near = 0.1;
const far = 100;

// Define front and back cameras
const frontCamera = new THREE.PerspectiveCamera(fov, aspect, near, far);
const backCamera = new THREE.PerspectiveCamera(fov, aspect, near, far);

// Position and rotate the cameras
frontCamera.position.z = 2;
backCamera.position.z = -2;
backCamera.rotation.y = Math.PI; // Rotate 180 degrees to face the text

// Adjust for aspect ratio
function adjustCameraForAspectRatio() {
    const aspectRatio = window.innerWidth / window.innerHeight;
    if (aspectRatio < 1) { // Portrait mode
        frontCamera.position.z = 5;
        backCamera.position.z = -5;
    } else { // Landscape mode
        frontCamera.position.z = 2;
        backCamera.position.z = -2;
    }
}

// Set the initial camera
let currentCamera = frontCamera;

const fontLoader = new FontLoader();
let originalFont, alternateFont;
const textMeshes = [];
const initialStates = [];

fontLoader.load('/fonts/Rock 3D_Regular.json', (font) => {
  originalFont = font;
  checkAndCreateTextMeshes();
});

fontLoader.load('/fonts/Tiny5_Regular.json', (font) => {
  alternateFont = font;
  checkAndCreateTextMeshes();
});

function checkAndCreateTextMeshes() {
  if (originalFont && alternateFont) {
    createTextMeshes(originalFont, alternateFont);
  }
}

function createTextMeshes(originalFont, alternateFont) {
  const text = 'BOUGA';
  const size = 0.5;
  const depth = 0.2;
  const letterSpacing = 0.6;

  text.split('').forEach((char, index) => {
    const font = originalFont; // Use originalFont for initial rendering
    const textGeometry = new TextGeometry(char, {
      font: font,
      size: size,
      depth: depth,
    });
    textGeometry.center(); // Center the text geometry

    const textMaterial = new THREE.MeshNormalMaterial({
      side: THREE.DoubleSide // Ensure the text is visible from both sides
    });
    const textMesh = new THREE.Mesh(textGeometry, textMaterial);
    textMesh.userData.char = char; // Store the character
    textMesh.userData.size = size; // Store the size
    textMesh.userData.depth = depth; // Store the depth
    textMesh.userData.isOriginalFont = true; // Flag to track the current font

    // Position each letter
    textMesh.position.x = (index * letterSpacing) - (letterSpacing * (text.length - 1)) / 2;
    textMeshes.push(textMesh);
    scene.add(textMesh);

    initialStates.push({
      position: textMesh.position.clone(),
      rotation: textMesh.rotation.clone(),
      scale: textMesh.scale.clone()
    });
  });

  // Set intervals for each character to change font
  textMeshes.forEach((textMesh, index) => {
    let interval;
    switch (textMesh.userData.char) {
      case 'B':
        interval = 200; // 0.2 seconds
        break;
      case 'O':
      case 'A':
        interval = 1000; // 1 second
        break;
      case 'U':
        interval = 750; // 0.75 seconds
        break;
      case 'G':
        interval = 400; // 0.4 seconds
        break;
      default:
        return;
    }
    setInterval(() => changeTextMeshFont(textMesh, originalFont, alternateFont), interval);
  });

  const composer = new EffectComposer(renderer);
  const renderPass = new RenderPass(scene, currentCamera); // Use currentCamera here
  composer.addPass(renderPass);

  const glitchPass = new GlitchPass();
  composer.addPass(glitchPass);

  window.addEventListener('scroll', () => {
    const scrollTop = window.scrollY;
    const scrollMax = document.documentElement.scrollHeight - window.innerHeight;
    const scrollFraction = scrollTop / scrollMax;

    textMeshes.forEach((textMesh, index) => {
      const initialState = initialStates[index];
      const rotation = scrollFraction * Math.PI * 2;
      const scale = 1 + scrollFraction * 3;
      
      textMesh.rotation.x = initialState.rotation.x + rotation;
      textMesh.rotation.y = initialState.rotation.y + rotation;
      textMesh.scale.set(
        initialState.scale.x * scale,
        initialState.scale.y * scale,
        initialState.scale.z * scale
      );
    });
  });

  const animate = () => {
    requestAnimationFrame(animate);
    composer.render();
  };

  adjustCameraForAspectRatio();
  animate();

  // Function to switch cameras
  setInterval(() => {
    currentCamera = currentCamera === frontCamera ? backCamera : frontCamera;
    renderPass.camera = currentCamera; // Update the render pass camera
  }, 2000); // Switch every 3 seconds
}

function changeTextMeshFont(textMesh, originalFont, alternateFont) {
  const char = textMesh.userData.char; // Access the stored character
  const size = textMesh.userData.size; // Access the stored size
  const depth = textMesh.userData.depth; // Access the stored depth

  // Toggle the font flag
  const newFont = textMesh.userData.isOriginalFont ? alternateFont : originalFont;
  textMesh.userData.isOriginalFont = !textMesh.userData.isOriginalFont;

  const textGeometry = new TextGeometry(char, {
    font: newFont,
    size: size,
    depth: depth,
  });
  textGeometry.center(); // Center the text geometry

  textMesh.geometry.dispose();
  textMesh.geometry = textGeometry;
}

window.addEventListener('resize', () => {
  frontCamera.aspect = window.innerWidth / window.innerHeight;
  backCamera.aspect = window.innerWidth / window.innerHeight;
  frontCamera.updateProjectionMatrix();
  backCamera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  adjustCameraForAspectRatio();
});